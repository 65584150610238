import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

import toastr from "toastr";
import "toastr/build/toastr.css";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
import { getShopVacation, setShopVacation, setShopVacationSuccessMsg } from "store/actions";
//i18n
import { withTranslation } from "react-i18next";

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 8,
        color: "#fff",
        paddingRight: 1,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 8,
        color: "#fff",
        paddingRight: 1,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const SidebarContent = (props) => {
  const ref = useRef();
  const [menuAccess, setMenuAccess] = useState([]);
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    let authLogin = localStorage.getItem("authUser");
    if (authLogin) {
      authLogin = JSON.parse(authLogin);
      setMenuAccess(authLogin.menu_access);
    }

    // if (accessToken){
    //   accessToken = JSON.parse(accessToken);
    //   accessToken = 'Bearer '+accessToken.token;
    // }
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const dispatch = useDispatch();
  const { vacation } = useSelector((state) => ({
    vacation: state.StaffReducer.vacation,
  }));
  const { data } = useSelector((state) => {
    // console.log(state.StaffReducer.data); // Log the entire state to the console
    return {
      data: state.StaffReducer.data,
    };
  });
 async function setShopVacations() {
    dispatch(setShopVacation());
    dispatch(getShopVacation());
   
    if (data?.message === "Successfully submited" && data?.data === 0) {
      toastr.success("Vacation trun ON");
    }else{
      toastr.success("Vacation trun OFF");
    }
  }

  useEffect(() => {
    dispatch(getShopVacation());

    // console.log(members,'groups')
  }, [dispatch]);

  let menus = "";

  if (menuAccess != null && menuAccess != undefined && menuAccess.length > 0) {
    menus = menuAccess.map((access, index) => {
      if (access == 1) {
        return (
          <>
            <li key="dashboard">
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li key="orders">
              <Link to="/orders">
                <i className="bx bxs-truck"></i>
                <span>{props.t("Orders")}</span>
              </Link>
            </li>
            <li key="tickets">
              <Link to="/tickets">
                <i className="bx bx-bookmark-plus"></i>
                <span>{props.t("Ticketing")}</span>
              </Link>
            </li>
            <li key="settelement">
              <Link to="/settelement">
                <i className="bx bxs-wallet-alt"></i>
                <span>{props.t("Settlements")}</span>
              </Link>
            </li>
            <li key="productrequest">
              <Link to="/productrequest">
                <i className="bx bxs-copy"></i>
                <span>{props.t("Product Requests")}</span>
              </Link>
            </li>
            <li key="bulkrequest">
              <Link to="/bulkrequest">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Bulk Requests")}</span>
              </Link>
            </li>
            <li key="staff">
              <Link to="/staff">
                <i className="bx bx-user"></i>
                <span>{props.t("Staff Members")}</span>
              </Link>
            </li>

            <li key="inventory">
              <Link to="/inventory">
                <i className="bx bx-building"></i>
                <span>{props.t("inventory")}</span>
              </Link>
            </li>

            <li key="group">
              <Link to="/group">
                <i className="bx bx-group"></i>
                <span>{props.t("Groups")}</span>
              </Link>
            </li>
            <li key="rating">
              <Link to="/rating">
                <i className="bx bxs-star"></i>
                <span>{props.t("Rating")}</span>
              </Link>
            </li>
            <li key="complaince-form">
              <Link to="/complaince-form">
                <i className="bx bxs-bar-chart-square"></i>
                <span>{props.t("Complaince Forms")}</span>
              </Link>
            </li>
            <li key="vacation">
              <Link>
                <i className="bx bxs-shopping-bag"></i>
                <span>
                  {props.t("Vacation")}{" "}
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#ec4561"
                    onChange={() => {
                      setShopVacations();
                    }}
                    checked={vacation}
                  />
                </span>
              </Link>
            </li>
          </>
        );
      } else if (access == 2) {
        return (
          <li key="dashboard">
            <Link to="/dashboard">
              <i className="bx bx-home-circle"></i>
              <span>{props.t("Dashboard")}</span>
            </Link>
          </li>
        );
      } else if (access == 3) {
        return (
          <li key="tickets">
            <Link to="/tickets">
              <i className="bx bx-bookmark-plus"></i>
              <span>{props.t("Ticketing")}</span>
            </Link>
          </li>
        );
      } else if (access == 4) {
        return (
          <li key="settelement">
            <Link to="/settelement">
              <i className="bx bxs-wallet-alt"></i>
              <span>{props.t("Settlements")}</span>
            </Link>
          </li>
        );
      } else if (access == 5) {
        return (
          <li key="staff">
            <Link to="/staff">
              <i className="bx bx-user"></i>
              <span>{props.t("Staff Members")}</span>
            </Link>
          </li>
        );
      } else if (access == 6) {
        return (
          <li key="vacations">
            <Link>
              <i className="bx bxs-shopping-bag"></i>
              <span>
                {props.t("Vacation")}{" "}
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#ec4561"
                  onChange={() => {
                    setShopVacations();
                  }}
                  checked={vacation}
                />
              </span>
            </Link>
          </li>
        );
      } else if (access == 7) {
        return (
          <li key="orders">
            <Link to="/orders">
              <i className="bx bxs-truck"></i>
              <span>{props.t("Orders")}</span>
            </Link>
          </li>
        );
      } else if (access == 8) {
        return (
          <li key="inventory">
            <Link to="/inventory">
              <i className="bx bx-building"></i>
              <span>{props.t("Inventory")}</span>
            </Link>
          </li>
        );
      } else if (access == 9) {
        return (
          <li key="productrequest">
            <Link to="/productrequest">
              <i className="bx bxs-copy"></i>
              <span>{props.t("Product Requests")}</span>
            </Link>
          </li>
        );
      } else if (access == 10) {
        return (
          <li key="group">
            <Link to="/group">
              <i className="bx bx-group"></i>
              <span>{props.t("Groups")}</span>
            </Link>
          </li>
        );
      } else if (access == 11) {
        return (
          <li key="complaince-form">
            <Link to="/complaince-form">
              <i className="bx bxs-bar-chart-square"></i>
              <span>{props.t("Complaince Forms")}</span>
            </Link>
          </li>
        );
      }
    });
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menus}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
