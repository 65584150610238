export const GET_PRODUCT_TYPE_LIST = "GET_PRODUCT_TYPE_LIST";
export const GET_PRODUCT_TYPE_LIST_FAIL = "GET_PRODUCT_TYPE_LIST_FAIL";
export const GET_PRODUCT_TYPE_LIST_SUCCESS = "GET_PRODUCT_TYPE_LIST_SUCCESS";

export const GET_PRODUCT_CATEGORY_LIST = "GET_PRODUCT_CATEGORY_LIST";
export const GET_PRODUCT_CATEGORY_FAIL = "GET_PRODUCT_CATEGORY_LIST_FAIL";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_LIST_SUCCESS";

export const GET_PRODUCT_COMPANY_LIST = "GET_PRODUCT_COMPANY_LIST";
export const GET_PRODUCT_COMPANY_LIST_FAIL = "GET_PRODUCT_COMPANY_LIST_FAIL";
export const GET_PRODUCT_COMPANY_LIST_SUCCESS =
  "GET_PRODUCT_COMPANY_LIST_SUCCESS";

export const GET_PRODUCT_REQUEST_LIST = "GET_PRODUCT_REQUEST_LIST";
export const GET_PRODUCT_REQUEST_LIST_FAIL = "GET_PRODUCT_REQUEST_LIST_FAIL";
export const GET_PRODUCT_REQUEST_LIST_SUCCESS =
  "GET_PRODUCT_REQUEST_LIST_SUCCESS";

export const SAVE_PRODUCT_REQUEST = "SAVE_PRODUCT_REQUEST";
export const SAVE_PRODUCT_REQUEST_FAIL = "SAVE_PRODUCT_REQUEST_FAIL";
export const SAVE_PRODUCT_REQUEST_SUCCESS = "SAVE_PRODUCT_REQUEST_SUCCESS";

export const CANCEL_PRODUCT_REQUEST = "CANCEL_PRODUCT_REQUEST";
export const CANCEL_PRODUCT_REQUEST_FAIL = "CANCEL_PRODUCT_REQUEST_FAIL";
export const CANCEL_PRODUCT_REQUEST_SUCCESS = "CANCEL_PRODUCT_REQUEST_SUCCESS";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";

export const SAVE_INVENTORY = "SAVE_INVENTORY";
export const SAVE_INVENTORY_FAIL = "SAVE_INVENTORY_FAIL";
export const SAVE_INVENTORY_SUCCESS = "SAVE_INVENTORY_SUCCESS";

export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";

export const CHANGE_INVENTORY = "CHANGE_INVENTORY";
export const CHANGE_INVENTORY_FAIL = "CHANGE_INVENTORY_FAIL";
export const CHANGE_INVENTORY_SUCCESS = "CHANGE_INVENTORY_SUCCESS";

export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const REMOVE_PRODUCT_FAIL = "REMOVE_PRODUCT_FAIL";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";

export const GET_PRODUCT_PARENT_LIST = "GET_PRODUCT_PARENT_LIST";
export const GET_PRODUCT_PARENT_LIST_FAIL = "GET_PRODUCT_PARENT_LIST_FAIL";
export const GET_PRODUCT_PARENT_LIST_SUCCESS =
  "GET_PRODUCT_PARENT_LIST_SUCCESS";

export const RESET_PFLAG = "RESET_PFLAG";

export const LOADING_STATUS = "LOADING_STATUS";

export const DOWNLOAD_PRODUCTS = "DOWNLOAD_PRODUCTS";

export const INVENTORY_DOWNLOAD_SUCCESS = "INVENTORY_DOWNLOAD_SUCCESS";

export const INVENTORY_DOWNLOAD_FAIL = "INVENTORY_DOWNLOAD_FAIL";
