import { takeLatest, put, call } from "redux-saga/effects";
import {
  POST_FORGET_PASSWORD,
  POST_FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";
import { postForgetPasswordSuccess } from "./actions";
import { forgetPassword, verifyOtp } from "../../../helpers/backend_helper";

function* postForgetPasswordSaga(action) {
  try {
    const res = yield call(forgetPassword, action.payload.email);
    if (res && res.status) {
      yield put(postForgetPasswordSuccess(res));
      if (res.message) {
        yield put({ type: POST_FORGET_PASSWORD_SUCCESS, payload: { successMessage: res.message } });
      }
    } else {
      console.log(res);
      yield put({ type: POST_FORGET_PASSWORD_ERROR, payload: { error: "Unexpected response format" } });
    }
  } catch (error) {
    yield put({ type: POST_FORGET_PASSWORD_ERROR, payload: { error: error.message } });
  }
}

function* watchForgetPassword() {
  yield takeLatest(POST_FORGET_PASSWORD, postForgetPasswordSaga);
}

function* verifyOtpSaga(action) {
  try {
    const res = yield call(verifyOtp, action.payload.data);
    if (res && res.status) {
      // Dispatch success action with message payload
      yield put({ type: VERIFY_OTP_SUCCESS, payload: { successMessage: res.message } });
    } else {
      throw new Error(res.message);
    }
  } catch (error) {
    yield put({ type: VERIFY_OTP_ERROR, payload: { error: error.message } });
  }
}

function* watchVerifyOtp() {
  yield takeLatest(VERIFY_OTP, verifyOtpSaga);
}

export { watchForgetPassword, watchVerifyOtp };
