
const Pdate = (cell) => {
    return cell.value ? cell.value : ''
}

const Ddate = (cell) => {
    return cell.value ? cell.value : ''
}

const Name = (cell) => {
    return cell.value ? cell.value : ''
}

const Idno = (cell) => {
    return cell.value ? cell.value : ''
}

const Budget = (cell) => {
    return cell.value ? cell.value : ''
}


export {
    Pdate,
    Ddate,
    Name,
    Idno,
    Budget
}