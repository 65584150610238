export const GET_GROUP_LIST = "GET_GROUP_LIST"
export const GET_GROUP_LIST_FAIL = "GET_GROUP_LIST_FAIL"
export const GET_GROUP_LIST_SUCCESS = "GET_GROUP_LIST_SUCCESS"
export const ADD_NEW_GROUP_LIST = "ADD_NEW_GROUP_LIST"
export const ADD_GROUP_LIST_SUCCESS = "ADD_GROUP_LIST_SUCCESS"
export const ADD_GROUP_LIST_FAIL = "ADD_GROUP_LIST_FAIL"
export const UPDATE_GROUP_LIST = "UPDATE_GROUP_LIST"
export const UPDATE_GROUP_LIST_SUCCESS = "UPDATE_GROUP_LIST_SUCCESS"
export const UPDATE_GROUP_LIST_FAIL = "UPDATE_GROUP_LIST_FAIL"
export const DELETE_GROUP_LIST = "DELETE_GROUP_LIST"
export const DELETE_GROUP_LIST_SUCCESS = "DELETE_GROUP_LIST_SUCCESS"
export const DELETE_GROUP_LIST_FAIL = "DELETE_GROUP_LIST_FAIL"
export const LOADING_STATUS = "LOADING_STATUS"





