import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.post['Content-Type'] = 'multipart/form-data'
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  // Check if data is FormData
  const isFormData = data instanceof FormData;
console.log(data)
  return axiosApi
    .post(url, isFormData ? data : { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}
