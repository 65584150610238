import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Nav, NavItem, NavLink } from "reactstrap";
import { StatisticsApplicationsChart } from "./JobCharts";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersLists } from "store/actions";

const StatisticsApplications = ({ statistic_data }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrdersLists());
  }, [dispatch]);
  const { orders } = useSelector((state) => ({
    orders: state.OrderReducer.orders,
  }));
  

//   console.log(orders.open);
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className="d-sm-flex flex-wrap">
              <h4 className="card-title mb-4">Sales Statistics</h4>
            </div>
            <div className="d-sm-flex flex-wrap">
              <div className="ms-auto">
                <Nav pills>
                  <NavItem>
                    <NavLink href="#">
                      <h6>
                        {/* {statistic_data?.pending_orders
                          ? statistic_data?.pending_orders
                          : 0} */}
                            {orders?.open?.filter(order=>order?.order_status==='pending')?.length}
                      </h6>
                      <h6>Pending Orders</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6>
                        {statistic_data?.monthly_orders
                          ? statistic_data?.monthly_orders
                          : 0}
                      </h6>
                      <h6>Monthly Orders</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6>
                        {statistic_data?.monthly_sales
                          ? statistic_data?.monthly_sales
                          : 0}
                          {/* {orders?.open?.filter(order=>order?.order_status==='pending')?.length} */}
                          
                      </h6>
                      <h6>Monthly Sales</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6>
                        {/* {statistic_data?.total_orders
                          ? statistic_data?.total_orders
                          : 0} */}
                          {orders?.open?.length}
                      </h6>
                      <h6>Total Orders</h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">
                      <h6>
                        {/* {statistic_data?.total_sales
                          ? statistic_data?.total_sales
                          : 0} */}
                          {orders?.open?.length}
                      </h6>
                      <h6>Total Sales</h6>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            {/* Assuming 'StatisticsApplicationsChart' receives 'seriesData' prop */}
            <StatisticsApplicationsChart
              seriesData={statistic_data}
              dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info"]'
              dir="ltr"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

StatisticsApplications.propTypes = {
  statistic_data: PropTypes.shape({
    pending_orders: PropTypes.number,
    monthly_orders: PropTypes.number,
    monthly_sales: PropTypes.number,
    total_orders: PropTypes.number,
    total_sales: PropTypes.number,
    // Add more properties as needed
  }),
};

export default StatisticsApplications;
