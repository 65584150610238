export const GET_MEMBER_LIST = "GET_MEMBER_LIST"
export const GET_MEMBER_LIST_FAIL = "GET_MEMBER_LIST_FAIL"
export const GET_MEMBER_LIST_SUCCESS = "GET_MEMBER_LIST_SUCCESS"
export const ADD_NEW_MEMBER_LIST = "ADD_NEW_MEMBER_LIST"
export const ADD_MEMBER_LIST_SUCCESS = "ADD_MEMBER_LIST_SUCCESS"
export const ADD_MEMBER_LIST_FAIL = "ADD_MEMBER_LIST_FAIL"
export const UPDATE_MEMBER_LIST = "UPDATE_MEMBER_LIST"
export const UPDATE_MEMBER_LIST_SUCCESS = "UPDATE_MEMBER_LIST_SUCCESS"
export const UPDATE_MEMBER_LIST_FAIL = "UPDATE_MEMBER_LIST_FAIL"

export const CHANGE_STATUS_MEMBER = "CHANGE_STATUS_MEMBER"
export const CHANGE_STATUS_MEMBER_SUCCESS = "CHANGE_STATUS_MEMBER_SUCCESS"
export const CHANGE_STATUS_MEMBER_FAIL = "CHANGE_STATUS_MEMBER_FAIL"
export const DELETE_MEMBER_LIST = "DELETE_MEMBER_LIST"
export const DELETE_MEMBER_LIST_SUCCESS = "DELETE_MEMBER_LIST_SUCCESS"
export const DELETE_MEMBER_LIST_FAIL = "DELETE_MEMBER_LIST_FAIL"
export const SHOP_VECATION="SHOP_VACATION"
export const SHOP_VECATION_SUCCESS="SHOP_VACATION_SUCCESS"
export const SHOP_VECATION_FAIL="SHOP_VACATION_FAIL"
export const GET_SHOP_VACATION="GET_SHOP_VACATION"
export const GET_SHOP_VACATION_FAIL="GET_SHOP_VACATION_FAIL"
export const GET_SHOP_VACATION_SUCCESS="GET_SHOP_VACATION_SUCCESS"
export const SHOP_COMPLIANCE ="SHOP_COMPLIANCE"
export const SHOP_COMPLIANCE_SUCCESS ="SHOP_COMPLIANCE_SUCCESS"
export const SHOP_COMPLIANCE_FAIL ="SHOP_COMPLIANCE_FAIL"
export const GET_SHOP_COMPLIANCE ="GET_SHOP_COMPLIANCE"
export const GET_SHOP_COMPLIANCE_FAIL ="GET_SHOP_COMPLIANCE_FAIL"
export const GET_SHOP_COMPLIANCE_SUCCESS ="GET_SHOP_COMPLIANCE_SUCCESS"
export const SHOP_BANK ="SHOP_BANK"
export const SHOP_BANK_SUCCESS ="SHOP_BANK_SUCCESS"
export const SHOP_BANK_FAIL ="SHOP_BANK_FAIL"
export const LOADING_STATUS ="LOADING_STATUS"
export const SHOP_VACATION_SUCCESS_MSG = "SHOP_VACATION_SUCCESS_MSG"





export const RESET_SFLAG = "RESET_PFLAG";






