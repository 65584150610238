import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD,
  POST_FORGET_PASSWORD_SUCCESS,
  POST_FORGET_PASSWORD_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  LOADING_FORGET_PASSWORD,
  LOADING_POST_FORGET_PASSWORD,
  LOADING_VERIFY_OTP,
} from "./actionTypes";

// Action creators for forget password
export const userForgetPassword = (user) => ({
  type: FORGET_PASSWORD,
  payload: { user },
});

export const userForgetPasswordSuccess = (message) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: message,
});

export const userForgetPasswordError = (message) => ({
  type: FORGET_PASSWORD_ERROR,
  payload: message,
});

// Action creators for post forget password
export const postForgetPassword = (email) => ({
  type: POST_FORGET_PASSWORD,
  payload: { email },
});

export const postForgetPasswordSuccess = (payload) => ({
  type: POST_FORGET_PASSWORD_SUCCESS,
  payload,
});

export const postForgetPasswordError = (error) => ({
  type: POST_FORGET_PASSWORD_ERROR,
  payload: { error },
  error: true,
  meta: {
    loading: false,
  },
});

// Action creators for verify OTP
export const verifyOtp = (data) => ({
  type: VERIFY_OTP,
  payload: { data },
});

export const verifyOtpSuccess = (message) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: message,
});


export const verifyOtpError = (error) => ({
  type: VERIFY_OTP_ERROR,
  payload: { error },
  error: true,
  meta: {
    loading: false,
  },
});

// Action creators for loading states
export const loadingForgetPassword = () => ({
  type: LOADING_FORGET_PASSWORD,
});

export const loadingPostForgetPassword = () => ({
  type: LOADING_POST_FORGET_PASSWORD,
});

export const loadingVerifyOtp = () => ({
  type: LOADING_VERIFY_OTP,
});
