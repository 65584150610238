// Action types for forget password
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

// Action types for post forget password
export const POST_FORGET_PASSWORD = "POST_FORGET_PASSWORD";
export const POST_FORGET_PASSWORD_SUCCESS = "POST_FORGET_PASSWORD_SUCCESS";
export const POST_FORGET_PASSWORD_ERROR = "POST_FORGET_PASSWORD_ERROR";

// Action types for verify OTP
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";

// Action types for loading states
export const LOADING_FORGET_PASSWORD = "LOADING_FORGET_PASSWORD";
export const LOADING_POST_FORGET_PASSWORD = "LOADING_POST_FORGET_PASSWORD";
export const LOADING_VERIFY_OTP = "LOADING_VERIFY_OTP";
