import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import {
  postForgetPassword,
  postForgetPasswordSuccess,
  verifyOtp,
} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { toast } from "react-toastify";

const ForgetPasswordPage = (props) => {
  //meta title
  document.title = "Forget Password | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const loading = useSelector((state) => state.ForgetPassword.loading);
  const successMessage = useSelector(
    (state) => state.ForgetPassword.forgetSuccessMsg
  );
  const errorMsg = useSelector((state) => state.ForgetPassword.error);

  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
      otp: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().min(8, "Password must be at least 8 characters"),

      confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),

      otp: Yup.string(),
    }),
    onSubmit: (values) => {
      try {
        console.log(errorMsg === ""); // Log the form values

        // Dispatch the action to initiate the forget password process
        dispatch(postForgetPassword({ email: values.email }));
      } catch (error) {
        console.error("Something went wrong", error);
        // Handle any errors if necessary
      }
      //  const res = dispatch(postForgetPasswordSuccess())
      //  if(res.payload){
      //   console.log(res)
      //  }
      // if (res.type === "POST_FORGET_PASSWORD") {

      // } else {
      //   setShowAdditionalFields(false);
      // }
    },
  });

  useEffect(() => {
    console.log(successMessage === "Password changed successfully.");
    if (successMessage === "Password changed successfully.") {
      navigate("/login");
    }
  }, [successMessage]);

  useEffect(() => {
    if (successMessage === "Email sent successfully.") {
      setShowAdditionalFields(true);
    }
  }, [successMessage]);

  const handleAdditionalFieldsSubmit = () => {
    // console.log(value);
    dispatch(
      verifyOtp({
        email: validation.values.email,
        password: validation.values.password,
        confirm_password: validation.values.confirm_password,
        otp: validation.values.otp,
      })
    );
  };

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {showAdditionalFields && (
                        <>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                              name="password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password}
                              invalid={
                                validation.touched.password &&
                                !!validation.errors.password
                              }
                            />
                            {validation.touched.password &&
                              validation.errors.password && (
                                <div className="invalid-feedback">
                                  {validation.errors.password}
                                </div>
                              )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Confirm Password
                            </label>
                            <input
                              name="confirm_password"
                              className="form-control"
                              placeholder="Confirm password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password}
                              invalid={
                                validation.touched.confirm_password &&
                                !!validation.errors.confirm_password
                              }
                            />
                            {validation.touched.confirm_password &&
                              validation.errors.confirm_password && (
                                <div className="invalid-feedback">
                                  {validation.errors.confirm_password}
                                </div>
                              )}
                          </div>
                          <div className="mb-3">
                            <label className="form-label">OTP</label>
                            <input
                              name="otp"
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otp}
                              invalid={
                                validation.touched.otp &&
                                !!validation.errors.otp
                              }
                            />
                            {validation.touched.otp &&
                              validation.errors.otp && (
                                <div className="invalid-feedback">
                                  {validation.errors.otp}
                                </div>
                              )}
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="button"
                                onClick={handleAdditionalFieldsSubmit}
                              >
                                {loading ? "Loading..." : "Submit"}
                              </button>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="mb-3">
                        <Col className="text-end">
                          {!showAdditionalFields && (
                            <button
                              className="btn btn-primary w-md"
                              type="submit"
                            >
                              {loading ? "Loading..." : "Reset"}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
