import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";

const Rating = () => {
  const initialData = [
    { title: "Sample Title 1", date: "2022-01-01", rating: 4.5 },
    { title: "Sample Title 2", date: "2022-01-15", rating: 3.8 },
    { title: "Sample Title 3", date: "2022-01-01", rating: 4.5 },
    { title: "Sample Title 4", date: "2022-01-15", rating: 3.8 },
    { title: "Sample Title 5", date: "2022-01-01", rating: 4.5 },
    { title: "Sample Title 6", date: "2022-01-15", rating: 3.8 },
    { title: "Sample Title 7", date: "2022-01-01", rating: 4.5 },
    { title: "Sample Title 8", date: "2022-01-15", rating: 3.8 },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  const handleSearch = (query) => {
    setSearchQuery(query);

    // Filter the data based on the search query
    const filtered = initialData.filter(
      (item) =>
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item.date.includes(query) ||
        item.rating.toString().includes(query)
    );

    setFilteredData(filtered);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="shadow-sm rounded w-100 mx-auto px-4 py-3 bg-white">
            <h4>Rating</h4>
          </div>

          <div className="shadow-sm my-4  rounded w-100 mx-auto px-4 py-3 bg-white">
            <div className="d-flex justify-content-between">
              <div>Search Result - ({filteredData.length})</div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
            <br />
            {/* Data */}
            <div className="my-4">
              {filteredData.length === 0 ? (
                <p className="text-center">No data found</p>
              ) : (
                <Table responsive size="lg" striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.title}</td>
                        <td>{item.date}</td>
                        <td>{item.rating}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rating;
