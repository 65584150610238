import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD,
  POST_FORGET_PASSWORD_SUCCESS,
  POST_FORGET_PASSWORD_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  // otpSuccessMsg: null, // Include otpSuccessMsg in the initial state
  // forgetError: null,
  loading: false,
  success: false,
  error: null,
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        forgetSuccessMsg: null,
        // otpSuccessMsg: null, // Reset otpSuccessMsg
        // forgetError: null,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetSuccessMsg: action.payload,
        // forgetError: null,
        loading: false,
        success: true,
        error: null,
      };
    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        // forgetSuccessMsg: null,
        otpSuccessMsg: null, // Reset otpSuccessMsg
        error: action.payload,
        loading: false,
        success: false,
        forgetSuccessMsg: action.payload.error,
      };
    case POST_FORGET_PASSWORD:
    case VERIFY_OTP:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case POST_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        forgetSuccessMsg: action.payload.successMessage,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        forgetSuccessMsg: action.payload.successMessage, // Set otpSuccessMsg
      };
    case POST_FORGET_PASSWORD_ERROR:
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        forgetSuccessMsg: action.payload.error,
      };
    default:
      return state;
  }
};

export default forgetPassword;
