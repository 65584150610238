import React, { useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import { getTicketDetails as onGetTicketDetail } from "store/actions"
import ProjectDetail from "./projectDetail"
import TeamMembers from "./teamMembers"
import AttachedFiles from "./attachedFiles"
import Comments from "./comments"

import { useSelector, useDispatch } from "react-redux"

const TicketDetails = (props) => {
  document.title = "Ticket Details | Pharmwale"

  const dispatch = useDispatch()

  const { ticket_detail } = useSelector((state) => ({
    ticket_detail: state.OrderReducer.ticket_detail,
  }))

  const params = props.router.params

  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetTicketDetail(params.id))
    }
  }, [dispatch, params.id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ticket" breadcrumbItem="Ticket Detail" />

          {isEmpty(ticket_detail) ? (
            <p>Loading...</p>
          ) : (
            <>
              <Row>
                <Col lg="12">
                  <ProjectDetail ticket={ticket_detail} />
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <TeamMembers history={ticket_detail?.history} ticket={ticket_detail} />
                </Col>
                <Col lg="8">
                  <AttachedFiles files={ticket_detail?.images} />
                  <Comments comments={ticket_detail?.message} ticket={ticket_detail} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

TicketDetails.propTypes = {
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

export default withRouter(TicketDetails)
