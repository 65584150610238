/* ORDERS */
export const GET_DASHBOARD = "GET_DASHBOARD"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL"

/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"
export const GET_ORDER_TRACK= "GET_ORDER_TRACK"
export const GET_ORDER_TRACK_FAIL= "GET_ORDER_TRACK_FAIL"
export const GET_ORDER_TRACK_SUCCESS= "GET_ORDER_TRACK_SUCCESS"
export const GET_ALL_ORDERS ="GET_ALL_ORDERS"
export const GET_ALL_ORDERS_SUCCESS ="GET_ALL_ORDERS_SUCCESS"
export const GET_ALL_ORDERS_FAIL ="GET_ALL_ORDERS_FAIL"

/* SETTLEMENT */

/* ORDERS */
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL"
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS"
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL"

/**
 * add ORDER
 */
export const ADD_NEW_ORDER = "ADD_NEW_ORDER"
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS"
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"


/* Ticket */
export const GET_TICKETS = "GET_TICKETS"
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS"
export const GET_TICKETS_FAIL = "GET_TICKETS_FAIL"

/* Ticket */
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL"
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS"
export const GET_TICKET_DETAIL_FAIL = "GET_TICKET_DETAIL_FAIL"

/**
 * add ORDER
 */
export const ADD_NEW_TICKET = "ADD_NEW_TICKET"
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS"
export const ADD_TICKET_FAIL = "ADD_TICKET_FAIL"

export const LOADING_STATUS = "LOADING_STATUS"


export const ADD_TICKET_MESSAGE ="ADD_TICKET_MESSAGE"
export const ADD_TICKET_MESSAGE_SUCCESS ="ADD_TICKET_MESSAGE_SUCCESS"
export const ADD_TICKET_MESSAGE_FAIL ="ADD_TICKET_MESSAGE_FAIL"
export const ORDER_STATUS="ORDER_STATUS"
export const ORDER_STATUS_SUCCESS="ORDER_STATUS_SUCCESS"
export const ORDER_STATUS_FAIL="ORDER_STATUS_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_TICKET = "UPDATE_TICKET"
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS"
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL"

/**
 * SETTLEMENTS
 */
export const GET_SETTLEMENTS = "GET_SETTLEMENTS"
export const GET_SETTLEMENTS_SUCCESS = "GET_SETTLEMENTS_SUCCESS"
export const GET_SETTLEMENTS_FAIL = "GET_SETTLEMENTS_FAIL"


export const SAVE_PROCESS = "SAVE_PROCESS"
export const SAVE_PROCESS_FAIL = "SAVE_PROCESS_FAIL"
export const SAVE_PROCESS_SUCCESS = "SAVE_PROCESS_SUCCESS"

export const DOWNLOAD_ORDERS= "DOWNLOAD_ORDERS"
export const DOWNLOAD_ORDERS_SUCCESS= "DOWNLOAD_ORDERS_SUCCESS"
export const DOWNLOAD_ORDERS_FAIL= "DOWNLOAD_ORDERS_FAIL"

export const DELETE_OLD_MENEFIST= "DELETE_OLD_MENEFIST"

export const RESET_FLAG= "RESET_FLAG"

export const SAVE_DOCKET_IMAGE ="SAVE_DOCKET_IMAGE"
export const SAVE_DOCKET_IMAGE_FAIL ="SAVE_DOCKET_IMAGE_FAIL"
export const SAVE_DOCKET_IMAGE_SUCCESS ="SAVE_DOCKET_IMAGE_SUCCESS"

export const SAVE_MENIFEST_ORDER ="SAVE_MENIFEST_ORDER"
export const SAVE_MENIFEST_ORDER_SUCCESS ="SAVE_MENIFEST_ORDER_SUCCESS"
export const SAVE_MENIFEST_ORDER_FAIL ="SAVE_MENIFEST_ORDER_FAIL"