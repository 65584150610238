import React, { useState } from "react";
import { Container, Row, Table } from "reactstrap";

const BulkRequest = () => {
  const initialData = [
    {
      productName: "Medicine X",
      discount: 15,
      quantity: 120,
      date: "2022-03-05",
      status: "Pending",
      action: "View Details",
    },
    {
      productName: "Medicine Y",
      discount: 8,
      quantity: 80,
      date: "2022-03-10",
      status: "Shipped",
      action: "Track Order",
    },
    {
      productName: "Medicine Z",
      discount: 12,
      quantity: 100,
      date: "2022-03-15",
      status: "Delivered",
      action: "Reorder",
    },
    {
      productName: "Painkiller A",
      discount: 10,
      quantity: 50,
      date: "2022-03-20",
      status: "Pending",
      action: "View Details",
    },
    {
      productName: "Antibiotic B",
      discount: 5,
      quantity: 30,
      date: "2022-03-25",
      status: "Shipped",
      action: "Track Order",
    },
    {
      productName: "Vitamin C",
      discount: 20,
      quantity: 200,
      date: "2022-04-01",
      status: "Delivered",
      action: "Reorder",
    },
    {
      productName: "Allergy Medicine",
      discount: 15,
      quantity: 70,
      date: "2022-04-05",
      status: "Pending",
      action: "View Details",
    },
    {
      productName: "Cold Syrup",
      discount: 10,
      quantity: 40,
      date: "2022-04-10",
      status: "Shipped",
      action: "Track Order",
    },
    {
      productName: "Digestive Aid",
      discount: 18,
      quantity: 90,
      date: "2022-04-15",
      status: "Delivered",
      action: "Reorder",
    },
    {
      productName: "Sleep Aid",
      discount: 12,
      quantity: 60,
      date: "2022-04-20",
      status: "Pending",
      action: "View Details",
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(initialData);

  const handleSearch = (query) => {
    setSearchQuery(query);

    const filtered = initialData.filter(
      (item) =>
        item.productName.toLowerCase().includes(query.toLowerCase()) ||
        item.date.includes(query) ||
        item.discount.toString().includes(query) ||
        item.quantity.toString().includes(query) ||
        item.status.toLowerCase().includes(query) ||
        item.action.toLowerCase().includes(query)
    );

    setFilteredData(filtered);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="shadow-sm rounded w-100 mx-auto px-4 py-3 bg-white">
              <h4>Bulk Requests</h4>
            </div>

            <div className="shadow-sm my-4  rounded w-100 mx-auto px-4 py-3 bg-white">
              <div className="d-flex justify-content-between">
                <div>Search Result - ({filteredData.length})</div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
              </div>
              <br />
              {/* Data */}
              <div className="my-4">
                {filteredData.length === 0 ? (
                  <p className="text-center">No data found</p>
                ) : (
                  <Table responsive size="lg" striped>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>Additional expected discount</th>
                        <th>Quantity</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.productName}</td>
                          <td>{item.discount} % discount per product</td>
                          <td>{item.quantity}</td>
                          <td>{item.date}</td>
                          <td>{item.status}</td>
                          <td>{item.action}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default BulkRequest;
