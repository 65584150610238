import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_GROUP_LIST,
  ADD_NEW_GROUP_LIST,
  UPDATE_GROUP_LIST,
  DELETE_GROUP_LIST,
} from "./actionTypes";
import {
  getGroupListSuccess,
  getGroupListFail,
  addGroupListSuccess,
  addGroupListFail,
  updateGroupListSuccess,
  updateGroupListFail,
  deleteGroupListSuccess,
  deleteGroupListFail,
  loadingGroupStatus,
} from "./actions";

import {
  updateGroup,
  getSellerGroups,
  addNewGroupList,
  // deleteJobList,
} from "helpers/fakebackend_helper";
import { deleteGroup } from "helpers/backend_helper";

function* fetchSellerGroupsList() {
  try {
    yield put(loadingGroupStatus(true));
    const response = yield call(getSellerGroups);
    if (response.status == true) {
      yield put(loadingGroupStatus(false));
      yield put(getGroupListSuccess(response.data));
    } else {
      yield put(loadingGroupStatus(false));
      yield put(getGroupListFail(response.message));
    }
  } catch (error) {
    yield put(loadingGroupStatus(false));
    yield put(getGroupListFail(error));
  }
}

function* onAddNewGroupList({ payload: data }) {
  try {
    yield put(loadingGroupStatus(true));
    const response = yield call(addNewGroupList, data);
    if (response.status == true) {
      yield put(loadingGroupStatus(false));
      yield put(addGroupListSuccess(response.data));
    } else {
      yield put(loadingGroupStatus(false));
      yield put(addGroupListFail(response.message));
    }
  } catch (error) {
    yield put(loadingGroupStatus(false));
    yield put(addGroupListFail(error));
  }
}

function* onUpdateGroupList({ payload: data }) {

  console.log(data)
  try {
    yield put(loadingGroupStatus(true));
    const response = yield call(updateGroup, data);
    console.log(response);
    if (response.status == true) {
      yield put(loadingGroupStatus(false));
      yield put(updateGroupListSuccess(response.data));
    } else {
      yield put(loadingGroupStatus(false));
      yield put(updateGroupListFail(response.message));
    }
  } catch (error) {
    yield put(loadingGroupStatus(false));
    yield put(updateGroupListFail(error));
  }
}

// function* deleteGroupList({ payload: groupId }) {
//     try {
//         yield put(loadingGroupStatus(true));
//         const response = yield call(deleteGroup, groupId);
//         console.log(response);

//         if (response && response.status === 200) { // Assuming 200 is success status
//             yield put(deleteGroupListSuccess(response.data)); // Assuming response.data contains relevant data
//         } else {
//             yield put(deleteGroupListFail(response.data.message)); // Assuming response.data.message contains error message
//         }
//     } catch (error) {
//         yield put(deleteGroupListFail(error.message));
//     } finally {
//         yield put(loadingGroupStatus(false));
//     }
// }
function* deleteGroupList(action) {
  // console.log(action.payload)
  try {
    const response = yield call(deleteGroup, action.payload);
    // console.log(response)
    if (response && response.status === false) {
      yield put({ type: deleteGroupListFail, payload: response.message });
    } else {
      yield put({ type: deleteGroupListSuccess, payload: action.payload });
    }
  } catch (error) {
    yield put({ type: deleteGroupListFail, payload: error.message });
  }
}

function* groupsSaga() {
  yield takeEvery(GET_GROUP_LIST, fetchSellerGroupsList);
  yield takeEvery(ADD_NEW_GROUP_LIST, onAddNewGroupList);
  yield takeEvery(UPDATE_GROUP_LIST, onUpdateGroupList);
  yield takeEvery(DELETE_GROUP_LIST, deleteGroupList);
}

export default groupsSaga;
