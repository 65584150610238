// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
//import components
import TableContainer from "../../components/Common/TableContainer";
import { Row } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Col, Card, CardBody, CardTitle, Modal } from "reactstrap";
import { Alert, FormFeedback } from "reactstrap";

import {
  getMemberList,
  addNewMemberList,
  getGroupList,
  updateMemberList,
  updateMemberStatus,
  deleteMemberList,
  resetSFlag,
} from "store/actions";
import ChangeStatusModal from "../../components/Common/ChangeStatusModal";
import { Label, Form, Input } from "reactstrap";
function StaffMedical() {
  const [edit, setEdit] = useState();
  const [deleteMember, setDeleteMember] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: 0,
      email: "",
      name: "",
      phone: "",
      group_type: "",
    },
    validationSchema: Yup.object({
      name: !edit ? Yup.string().required("Please Enter  Name") : Yup.string(),
      email: !edit
        ? Yup.string().required("Please Enter  Email")
        : Yup.string(),
      phone: !edit
        ? Yup.string().required("Please Enter  Phone")
        : Yup.string(),
      group_type: !edit
        ? Yup.string().required("Please Select  Group")
        : Yup.string(),
    }),
    onSubmit: (values) => {
      // console.log(edit);
      if (edit) {
        // console.log("update");
        dispatch(updateMemberList(values));
        setmodal_xlarge(false);
        validation.resetForm();
      } else {
        // console.log("add");
        dispatch(addNewMemberList(values));
        setmodal_xlarge(false);
        validation.resetForm();
      }

      // setmodal_xlarge(false);
      // tog_xlarge(false);
      dispatch(getMemberList());
      // window.location.reload();
    },
  });

  useEffect(() => {
    if (!edit) {
      validation.resetForm({
        values: validation?.initialValues,
      });
    }
  }, [edit, validation?.initialValues]);

  const { error, success } = useSelector((state) => ({
    error: state.StaffReducer.error,
    success: state.StaffReducer.success,
  }));

  const [request, setRequest] = useState(null);
  const [requestModal, setRequestModal] = useState(false);
  const onClickChangeRequest = (request) => {
    // console.log(request)
    setRequest(request);
    setRequestModal(true);
  };

  const handleRequestChange = () => {
    // console.log(request)
    if (request && request.id) {
      if (request.status == "approved") {
        dispatch(updateMemberStatus({ id: request.id, status: "pending" }));
      } else {
        dispatch(updateMemberStatus({ id: request.id, status: "approved" }));
      }
      dispatch(getMemberList());
    }
    setRequestModal(false);
  };
  const handleDeleteMember = () => {
    dispatch(deleteMemberList({ id: request.id }));
    setTimeout(() => {
      dispatch(getMemberList());
      setRequestModal(false);
    }, 200);
  };

  useEffect(() => {
    if (!error == "" || !success == "") {
      dispatch(resetSFlag());
    }
  }, [error, success]);

  const onClickEditMember = (request) => {
    // console.log(request)
    setRequest(request);
    validation.setFieldValue("id", request.id);
    validation.setFieldValue("name", request.f_name);
    validation.setFieldValue("email", request.email);
    validation.setFieldValue("phone", request.phone);
    validation.setFieldValue("group_type", request.group_type);
    tog_xlarge();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "f_name",
      },
      {
        Header: "Email ID",
        accessor: "email",
      },
      {
        Header: "Group",
        accessor: "group_name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <div className="d-flex flex-wrap gap-3">
                <div
                  className="btn-group"
                  role="group"
                  onClick={() => {
                    let viewData = cellProps.row.original;
                    setDeleteMember(false);
                    console.log(viewData);
                    // console.log(cellProps.row.original)
                    onClickEditMember(viewData);
                    setEdit(true);
                  }}
                >
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btnradio4"
                  >
                    Edit Member
                  </label>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  onClick={() => {
                    const requestData = cellProps.row.original;
                    setEdit(false);
                    setDeleteMember(true);
                    onClickChangeRequest(requestData);
                  }}
                >
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btnradio4"
                  >
                    Delete Member
                  </label>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  onClick={() => {
                    const requestData = cellProps.row.original;
                    setEdit(false);
                    setDeleteMember(false);
                    onClickChangeRequest(requestData);
                  }}
                >
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btnradio4"
                  >
                    {/* {console.log(cellProps,'cell')} */}
                    {cellProps.row.original.status == "approved"
                      ? "Deactivate Member"
                      : "Activate Member"}
                  </label>
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const dispatch = useDispatch();
  const { members, loading } = useSelector((state) => ({
    members: state.StaffReducer.members,
    loading: state.StaffReducer.loading,
  }));

  const { groups } = useSelector((state) => ({
    groups: state.GroupReducer.groups,
  }));

  useEffect(() => {
    dispatch(getMemberList());

    // console.log(members,'groups')
  }, [dispatch]);

  useEffect(() => {
    if (success != "" && loading == false) {
      // tog_xlarge();
      dispatch(getMemberList());
    }

    // console.log(members,'groups')
  }, [dispatch, success]);

  useEffect(() => {
    dispatch(getGroupList());
  }, [dispatch]);

  const [modal_xlarge, setmodal_xlarge] = useState(false);
  function tog_xlarge() {
    //console.log(modal_xlarge);
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  //meta title
  document.title = "Staff Merchent | Pharmwale";

  const value = members.find((member) => {
    if (member.id == validation.values.id) {
      return member;
    }
  });

  return (
    <div className="page-content">
      <div className="container-fluid">
        {/* <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="font-weight-bold">Staff Members</h2>
                    <button className="btn btn-outline-primary">
                        Add Staff Members +
                    </button>
                </div> */}
        {/* <Breadcrumbs title="Tables" breadcrumbItem="Data Tables" /> */}
        {/* <Table columns={columns} data={data} /> */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="font-weight-bold">Staff Member</h2>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              tog_xlarge();
              setEdit(false);
            }}
            data-toggle="modal"
            data-target=".bs-example-modal-xl"
          >
            Add Staff Member +
          </button>
          <Modal
            size="xl"
            isOpen={modal_xlarge}
            toggle={() => {
              tog_xlarge();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                {edit ? "Edit" : "Add"} Member
              </h5>
              <button
                onClick={() => {
                  setmodal_xlarge(false);
                  setEdit(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                console.log(validation);
                // dispatch(getMemberList());
                return false;
              }}
            >
              <div className="modal-body">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"> </CardTitle>

                    {error ? <Alert color="danger">{error}</Alert> : null}

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">Name</Label>
                          <Input
                            name="name"
                            value={
                              validation.values.name || value?.f_name || ""
                            }
                            type="text"
                            placeholder="Enter Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />

                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Phone</Label>
                          <Input
                            name="phone"
                            value={validation.values.phone || ""}
                            type="text"
                            placeholder="Enter Phone"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone &&
                              validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                          validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Group</Label>
                          <select
                            id="formrow-InputState"
                            className={
                              validation.touched.group_type &&
                              validation.errors.group_type
                                ? "is-invalid  form-control"
                                : "form-control"
                            }
                            name="group_type"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          >
                            <option>Choose...</option>
                            {groups.length > 0 ? (
                              groups.map((group, i) => (
                                <option
                                  key={i}
                                  value={group.id}
                                  selected={
                                    validation.values.group_type === group.id
                                  }
                                >
                                  {group.name}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                          </select>
                          {/*      {validation.touched.group_type &&
                          validation.errors.group_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.group_type}
                            </FormFeedback>
                          ) : null} */}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => tog_xlarge()}
                >
                  Close
                </button>

                {loading == false ? (
                  <button type="submit" className="btn btn-primary">
                    SUMBIT
                  </button>
                ) : (
                  <button className="btn btn-primary">Loading..</button>
                )}
              </div>
            </Form>
          </Modal>
        </div>
        {loading == false ? (
          <TableContainer
            columns={columns}
            data={members}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            className="custom-header-css"
          />
        ) : (
          <>
            <p>Loading...</p>
          </>
        )}
        <ChangeStatusModal
          show={requestModal}
          onChangeClick={
            deleteMember ? handleDeleteMember : handleRequestChange
          }
          onCloseClick={() => {
            setRequestModal(false);
            setDeleteMember(false);
          }}
          message={
            deleteMember
              ? "Are you Sure you want to delete the staff member"
              : request?.status === "approved"
              ? "Are you Sure you want to cancel staff status"
              : "Are you Sure you want to Active staff status"
          }
          buttonText={deleteMember ? "Delete Now" : "Change Now"}
        />
      </div>
    </div>
  );
}
StaffMedical.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default StaffMedical;
